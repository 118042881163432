header.navigation .contents,
nav.internal,
nav.external {
  display: flex;
  align-items: center;
}

nav.internal {
  gap: 8px;
  justify-content: left;
}

nav.external {
  gap: 16px;
  justify-content: right;
}

nav.internal > a {
  color: var(--gray-color);

  border-radius: 8px;

  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

nav.internal > a.selected {
  background: var(--gray-background);
}

nav.internal > a:not(.selected):hover {
  color: var(--color);
  background-color: var(--gray-dark-background);
}

nav.external > a > svg {
  height: 34px;
  width: 34px;

  color: var(--gray-color);
}

nav.external > a > svg:hover {
  color: var(--color);
}

@media screen and (width < 720px) {
  header.navigation {
    margin-top: 0px;
    width: 100%;
  }

  header.navigation .contents {
    flex-direction: column;
    display: none;
  }

  nav.internal {
    flex-direction: column;

    margin-bottom: 5px;
  }

  .hamburger {
    position: absolute;
    top: 5px;
    right: 5px;

    cursor: pointer;
    display: inline;
  }

  .hamburger > svg {
    height: 34px;
    width: 34px;
  }

  nav.external {
    margin-top: 5px;
  }
}

@media screen and (width >= 720px) {
  header.navigation {
    width: 90%;
    margin-top: 20px;
  }

  header.navigation .contents {
    flex-direction: row;
  }

  .hamburger {
    display: none;
  }
}

@media screen and (width >= 900px) {
  header.navigation {
    width: 800px;
  }
}

header.navigation .contents {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  background-color: var(--dark-background);
  border: 2px solid var(--border);
  border-radius: 8px;

  justify-content: space-between;
}
