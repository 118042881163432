header.navigation .contents, nav.internal, nav.external {
  align-items: center;
  display: flex;
}

nav.internal {
  justify-content: left;
  gap: 8px;
}

nav.external {
  justify-content: right;
  gap: 16px;
}

nav.internal > a {
  color: var(--gray-color);
  border-radius: 8px;
  padding: 8px 16px;
}

nav.internal > a.selected {
  background: var(--gray-background);
}

nav.internal > a:not(.selected):hover {
  color: var(--color);
  background-color: var(--gray-dark-background);
}

nav.external > a > svg {
  width: 34px;
  height: 34px;
  color: var(--gray-color);
}

nav.external > a > svg:hover {
  color: var(--color);
}

@media screen and (width < 720px) {
  header.navigation {
    width: 100%;
    margin-top: 0;
  }

  header.navigation .contents {
    flex-direction: column;
    display: none;
  }

  nav.internal {
    flex-direction: column;
    margin-bottom: 5px;
  }

  .hamburger {
    cursor: pointer;
    display: inline;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .hamburger > svg {
    width: 34px;
    height: 34px;
  }

  nav.external {
    margin-top: 5px;
  }
}

@media screen and (width >= 720px) {
  header.navigation {
    width: 90%;
    margin-top: 20px;
  }

  header.navigation .contents {
    flex-direction: row;
  }

  .hamburger {
    display: none;
  }
}

@media screen and (width >= 900px) {
  header.navigation {
    width: 800px;
  }
}

header.navigation .contents {
  background-color: var(--dark-background);
  border: 2px solid var(--border);
  border-radius: 8px;
  justify-content: space-between;
  padding: 8px 16px;
}
/*# sourceMappingURL=index.885e67a3.css.map */
